@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');

/*** DEFAULT STYLES ***/
* {
	box-sizing: border-box;
}

body {
	margin: 0;
	color: #8DABC4;
	background-color: #ECF5FD;
	font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif;
	-webkit-font-smoothing: antialiased; /** Smooth chrome font **/
	-moz-osx-font-smoothing: grayscale; /** smooth ff font **/
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004); /** everything else **/
	font-size: 16px;
	margin: 0;
}

p {
	margin: 0;
	padding: 0;
}

button:hover {
	cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: normal;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

input {
	color: #3C4A54;
	border: none;
	border-bottom: solid 1px #8DABC4;
	background-color: inherit;
	font-size: 16px;
	outline: none;
    font-family: 'Source Sans Pro', sans-serif;

	&[type="checkbox"] + label {
		display: inline;
		font-size: 14px;
		margin-left: 5px;
		vertical-align: middle;
	}
}

select:focus, select:active,
input:focus, select:active {
	animation: fadeInFocusBorder 0.4s 1;
	animation-fill-mode: forwards;
}