/** DETAILS SECTION **/
.details-section {
	.form-wrapper {
		@media only screen and (min-width: 730px) {
			max-width: 400px;
			margin: 0 auto;
		}

		.checkbox-group {
			margin-bottom: 40px;

			.checkbox-group-title {
				margin-bottom: 8px;
			}

			.domains_group {
				column-count: 2;

				.checkbox-wrapper {
					display: flex;
					align-items: center;
					height: 28px;
					page-break-inside: avoid;
					position: relative;

					.checkbox-label {
						color: #3C4A54;
					}
				}
			}
		}
	}

	.addons-wrapper {
		margin-bottom: 40px;

		.divider-container {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			max-width: 400px;
			margin: 20px auto;

			.line {
				flex-grow: 1;
				height: 1px;
				background-color: #83a7c4;
			}

			.label {
				margin: 0 12px;
				text-transform: uppercase;
				font-size: 14px;
				color: #83A3BF;
				font-weight: bold;
			}
		}

		.addons-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			&:hover {
				.addon-item-action {
					background-color: #2a354f;
					color: #fff;
				}
			}

			&.selected {
				.addon-item-action {
					background-color: #2a354f;
					color: #fff;
				}
			}

			.addon-item {
				display: flex;
				flex-direction: row;
				width: 360px;
				height: 160px;
				background-color: #fff;
				border-radius: 4px;
				border-top: 4px solid;
				border-color: #fff;
				-webkit-transition: border-color .15s ease-out;
				transition: border-color .15s ease-out;

				&.selected {
					border-color: #2a354f;

					.addon-item-action {
						background-color: #2a354f;
						color: #fff;
					}
				}

				&:not(:last-of-type) {
					margin-right: 16px;
				}

				.addon-item-header {
					padding: 16px 16px 0;

					.addon-name {
						font-size: 18px;
						color: #3C4A54;
					}

					.addon-price {
						font-size: 32px;
						color: #3C4A54;
					}
				}

				.addon-item-details {
 					display: flex;
					flex-direction: column;
					flex-grow: 1;
					margin: 20px 0;
					padding: 0 16px;

					.addon-item-detail {
						margin-bottom: 4px;

						&.sm {
							font-size: 14px;
						}

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}

			.addon-item-action {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				height: 40px;
				margin-right: 16px;
				width: 100%;
				align-items: center;
				border-top: 1px solid;
				border-color: #2a354f;
				color: #2a354f;
				background-color: #fff;
				border-radius: 0 0 4px 4px;
				-webkit-transition: all .15s ease-out;
				transition: all .15s ease-out;
				cursor: pointer;

				& > span {
					font-size: 18px;
				}
			}
		}
	}
}
