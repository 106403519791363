/*
 * Icon Font structurely-accounts
 * Made with love by Icons8 [ https://icons8.com/ ] using FontCustom [ https://github.com/FontCustom/fontcustom ]
 *
 * Contacts:
 *    [ https://icons8.com/contact ]
 *
 * Follow Icon8 on
 *    Twitter [ https://twitter.com/icons_8 ]
 *    Facebook [ https://www.facebook.com/Icons8 ]
 *    Google+ [ https://plus.google.com/+Icons8 ]
 *    GitHub [ https://github.com/icons8 ]
 */


 @font-face {
  font-family: "structurely-accounts";
  src: url("../fonts/structurely-accounts_0021ee18117f40edae23fa0ab2f6fd58.eot");
  src: url("../fonts/structurely-accounts_0021ee18117f40edae23fa0ab2f6fd58.eot?#iefix") format("embedded-opentype"),
       url("../fonts/structurely-accounts_0021ee18117f40edae23fa0ab2f6fd58.woff2") format("woff2"),
       url("../fonts/structurely-accounts_0021ee18117f40edae23fa0ab2f6fd58.woff") format("woff"),
       url("../fonts/structurely-accounts_0021ee18117f40edae23fa0ab2f6fd58.ttf") format("truetype"),
       url("../fonts/structurely-accounts_0021ee18117f40edae23fa0ab2f6fd58.svg#structurely-accounts") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "structurely-accounts";
    src: url("../fonts/structurely-accounts_0021ee18117f40edae23fa0ab2f6fd58.svg#structurely-accounts") format("svg");
  }
}

[data-icons8]:before { content: attr(data-icons8); }

.icons8, [data-icons8]:before,
.icons8-bank-card-back-side:before,
.icons8-building:before,
.icons8-checkmark:before,
.icons8-chevron-down:before,
.icons8-chevron-left:before,
.icons8-chevron-right:before,
.icons8-company:before,
.icons8-customer:before,
.icons8-delete:before,
.icons8-info:before,
.icons8-plus:before,
.icons8-user-account:before {
  display: inline-block;
  font-family: "structurely-accounts";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icons8-bank-card-back-side:before { content: "\f10c"; }
.icons8-building:before { content: "\f102"; }
.icons8-checkmark:before { content: "\f106"; }
.icons8-chevron-down:before { content: "\f10a"; }
.icons8-chevron-left:before { content: "\f105"; }
.icons8-chevron-right:before { content: "\f104"; }
.icons8-company:before { content: "\f103"; }
.icons8-customer:before { content: "\f100"; }
.icons8-delete:before { content: "\f107"; }
.icons8-info:before { content: "\f109"; }
.icons8-plus:before { content: "\f108"; }
.icons8-user-account:before { content: "\f101"; }