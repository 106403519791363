@import './styles/globals.scss';
@import './styles/icons.css';
@import './styles/native.scss';
@import './styles/notifications.css';

/*** BUTTON ***/
.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    -webkit-transition: all .15s ease-out;
	transition: all .15s ease-out;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	text-decoration: none;
    padding: 10px 0;
	width: 7em;
    border-radius: 4px;
    font-size: 16px;
	min-height: 40px;
	min-width: 200px;
	cursor: pointer;
	outline: none;
	text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
	
	&:hover {
		-webkit-transform: translateY(-2px);
		-ms-transform: translateY(-2px);
		transform: translateY(-2px);
	}

	&.primary-btn {
		background-color: #073D7D;
		color: #ffffff;
		box-shadow: 0 4px 8px rgba(7, 61, 125, .2),
					0 2px 6px rgba(7, 61, 125, .08);
	}

	&.success-btn {
		background-color: #2a354f;
		color: #ffffff;
		box-shadow: 0 4px 8px rgba(97, 165, 159, .2),
					0 2px 6px rgba(97, 165, 159, .08);		
	}

	&.aux-btn {
		background-color: #fff;
		color: #61A59F;
		width: auto;
		min-width: auto;
		padding: 0 20px;
	}

	&.disabled-btn {
		background-color: #bbb;
		cursor: auto;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	&.secondary-btn {
		border: 1px solid $green;
		color: $green;
		background-color: transparent;
	}
}

/*** PILL ***/
.pill {
	border: 1px solid #888;
	color: #888;
	font-size: 14px;
	border-radius: 14px;
	line-height: 1.9;
	padding: 0 8px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	&.success-pill {
		border-color: #81C926;
		color: #81C926;
	}
}

/*** SELECT ***/
.select-container {
    display: flex;
	flex-direction: column;

	& > .label {
		margin-bottom: 10px;
		font-size: 14px;
	}

	.select {
		position: relative;
		outline: none;
		min-width: 175px;
	
		.current-value {
			display: flex;
			justify-content: space-between;
			border: 1px solid #888;
			background-color: #fff;
			padding: 8px 10px;
			border-radius: 4px;
			align-items: center;
			font-size: 16px;
			cursor: pointer;

			p {
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: unset;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
	
			.icon {
				color: #888;
				margin-left: 10px;
			}
		}
	
		.dropdown-content {
			position: absolute;
			right: 0;
			z-index: 99;
			min-width: 160px;
			box-sizing: border-box;
			background-color: #fff;
			border: 1px solid rgba(0, 0, 0, 0.1);
			box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
			border-radius: 4px;
			width: 100%;
			margin-top: 0;
	
			&.lg {
				margin-right: -20px;
				min-width: 340px;
			}
	
			.options-list {
				list-style: none;
				padding: 0;
				margin: 0;
				border-radius: 6px;
				max-height: 200px;
				overflow-y: auto;
	
				li.option {
					display: flex;
					align-items: center;
					height: 50px;
					padding: 0px 20px;
					border-bottom: 1px solid #eee;
					cursor: pointer;
	
					& > a,
					& > span {
						color: #555;
					}
	
					&.options-header {
						background-color: #f7f7f7;
						border-radius: 5px 5px 0 0;
						font-weight: bold;
					}
	
					&.option-empty:hover {
						background-color: transparent;
					}
	
					&.selected {
						font-weight: bold;
						color: #2a354f;
						background-color: #ECF5FD;
						cursor: initial;
					}
	
					&:hover {
						background-color: #ECF5FD;
					}
	
					&.null {
						&:hover {
							background-color: white;
						}
					}
	
					&:last-of-type {
						border-radius: 0 0 5px 5px;
					}
				}
			}
		}
	}
}

/*** HEADER ***/
.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
	padding: 45px 20px 0;
	text-align: center;

	img {
		margin-bottom: 25px;
	}

	.title {
		margin-bottom: 20px;
		color: #3C4A54;
		font-size: 26px;

		@media only screen and (min-width: 730px) and (max-width: 949px) {
			font-size: 28px;
		}

		@media only screen and (min-width: 950px) {
			font-size: 30px;
		}
	}

	.subtitle {
		max-width: 300px;
		text-align: center;
		margin-bottom: 25px;
	}

	.notice-wrapper {
		max-width: 300px;
		padding: 10px;
		border: 2px solid #2a354f;
		border-radius: 4px;

		.notice {
			color: #61A59F;
		}
	}
}

/*** FOOTER ***/
.footer {
	position: relative;
	height: 200px;

	@media only screen and (min-width: 730px) {
		height: 325px;
	}

	&:after {
	   display: block;
	   clear: both;
	   position: absolute;
	   content: '';
	   height: 100%;
	   width: 100%;
	   bottom: 0;
	   left: 0;
	   z-index: -2;
	   background-image: url(/img/accounts-background.png);
	   background-size: 100% 200px;
	   background-repeat: no-repeat;
	   background-color: #ECF5FD;
	   background-position: bottom;

	   @media only screen and (min-width: 730px) {
		   background-size: 100% 325px;
	   }
	}

	& > img {
		position: absolute;
	}

	.footer-quotation {
	   position: absolute;
	   right: 30px;
	   top: 40px;
	   background-color: rgba(250, 251, 253, 0.8);
	   box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.07);
	   border-radius: 8px;
	   padding: 25px;
	   display: none;

	   @media only screen and (min-width: 730px) {
		   display: block;
	   }

		& > * {
		   text-align: right;
		   margin-bottom: 15px;
		}

	   .footer-header {
		   text-transform: uppercase;
		   color: #2a354f;
		   font-size: 16px;
		}
   
		.footer-quote {
		   color: #8D8D8D;
		   font-size: 15px;
		   max-width: 400px;
		}

		.footer-source {
		   color: #8D8D8D;
		   margin-bottom: 0;
		   font-size: 16px;
		   display: flex;
		   align-items: center;
		   justify-content: flex-end;

		   & > img {
			   margin-left: 10px;
		   }
		}
	}
}

/*** INPUT ***/
.input-container {
	margin-bottom: 40px;
	position: relative;

	&.error-input {
		margin-bottom: 20px;
	
		.select, .input {
			animation: fadeInErrorBorder 0.4s 1;
			animation-fill-mode: forwards;
		}
	}

	&.label-top > .label {
		top: -20px;
		font-size: 14px;
	}

	.label {
		z-index: -1;
		position: absolute;
		top: 6px;
		left: 0;
	}

	.input {
		width: 100%;
		height: 35px;

		&:focus + .label,
		&:active + .label {
			animation: moveLabelTopside 0.4s 1;
			animation-fill-mode: forwards;
		}
	}

	.error-wrapper {
		padding-top: 3px;
		z-index: -1;
	}

	.error {
		text-align: center;
		overflow: hidden;
		color: #D8000C;
		font-size: 14px;
		display: block;
		animation: fadeIn 0.4s 1;
		animation-fill-mode: forwards;
	}
}

/*** FORM ***/
.form-wrapper {
	max-width: 80%;
	margin: 0 auto;
	min-height: 300px;

	@media only screen and (min-width: 730px) {
		min-height: 450px;
		max-width: initial;
		margin: 0;
	}
	
	.form-action {
		display: flex;
		justify-content: center;
		margin-bottom: 40px;
		padding-top: 20px;
	}

	& > .form {
		padding: 0 20px;
	}

	.form-navigation {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;

		@media only screen and (min-width: 730px) {
			justify-content: center;
		}

		.btn {
			min-width: 150px;

			@media only screen and (min-width: 730px) {
				min-width: 200px;
			}
		}

		.nav-link {
			display: flex;
			align-items: center;
			color: #83A3BF;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			text-decoration: none;

			&.prev-link {
				@media only screen and (min-width: 730px) {
					margin-right: 25px;
				}				
			}

			&.next-link {
				@media only screen and (min-width: 730px) {
					margin-left: 25px;
				}
			}

			.icon {
				padding-top: 2px;
			}
		}
	}

	.form-links {
		padding: 0 20px;
		margin-bottom: 40px;
	
		.form-link {
			text-align: center;
	
			a {
				color: #0093EE;
				text-decoration: none;
			}
		}
	}
}

/*** LOADER **/
.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	.loader-spinner {
		border: 3px solid rgba(0, 0, 0, 0.1);
		border-left: 3px solid #fff;
		transform: translateZ(0);
		animation: spinner 1.1s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
		border-radius: 50%;
		width: 50px;
		height: 50px;

		&.spinner-sm {
			width: 25px;
			height: 25px;
		}

		&.spinner-md {
			width: 30px;
			height: 30px;
		}
	}
}

/*** Animations ***/
@keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
}

@keyframes fadeInFocusBorder {
	0% {
		border-color: #8DABC4;
	}
	100% {
		border-color: #2a354f;
	}
}

@keyframes moveLabelTopside {
	0% {
		top: 6;
	}
	100% {
		top: -20px;
		font-size: 14px;
	}
}

@keyframes fadeInErrorBorder {
	0% {
		border-color: #8DABC4;
	}
	100% {
		border-color: #D8000C;
	}
}

@keyframes slideErrorTextDownFromTop {
	0% {
		margin-top: -20px;
	}
	100% {
		margin-top: 0;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
