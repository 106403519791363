.integration-layout {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 20px;

	@media only screen and (min-width: 730px) {
		padding-top: 60px;
	}

	.integration-header {
		width: 100%;
		max-width: 400px;
		padding: 0 20px;
		margin-bottom: 20px;

		@media only screen and (min-width: 730px) {
			margin-bottom: 40px;
		}

		.integration-logo {
			flex: 1;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			height: 100px;
			transform: scale(1, 1);

			&.liondesk {
				background-image: url(/img/liondesk-logo.png);
			}
		}
	}

	.form-wrapper {
		width: 100%;
		max-width: 400px;
	}

	.integration-form {
		padding: 40px;

		.input-wrapper {
			margin-bottom: 20px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.btn-wrapper {
			margin-top: 40px;
		}
	}

	.connected-notice {
		padding: 30px;
		text-align: center;
		font-size: 18px;
		color: #2a354f;
	}
}