/* MEDIA QUERIES */
$highdensity: "only screen and (-webkit-min-device-pixel-ratio: 1.5)",
			  "only screen and (min--moz-device-pixel-ratio: 1.5)",
			  "only screen and (-o-min-device-pixel-ratio: 3/2)",
			  "only screen and (min-device-pixel-ratio: 1.5)";

$tablet:      "only screen and (min-width: 730px) and (max-width: 949px)";
$desktop:     "only screen and (min-width: 950px) and (max-width: 1128px)";
$desktop-xl:  "only screen and (min-width: 1129px)";

/* COLORS */
$green: #2a354f;

$boxShadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);