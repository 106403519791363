@import "../../../core/styles/globals.scss";

/*** PAYMENT PLAN ***/
.payment-plan-section {
	.header {
		margin-bottom: 20px;
	}

	.info-wrapper{
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.plan-features {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (max-width: 500px) {
			ul {
				display: flex;
				flex-direction: column;
			}
		}

		ul {
			columns: 2;

			& li {
				align-items: center;
				padding-top: 20px;
				font-size: 20px;
				&:before {
				  content: '✔';
				}
			}
		}
	}

	.payment-plan-form {
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		.plan-input {
			width: 300px;
			margin-bottom: 40px;

			&.hide {
				display: none;
			}
		}

		.plan-list {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			padding-top: 20px;
			margin-bottom: 20px;

			.plan-item {
				box-sizing: border-box;
				box-shadow: $boxShadow;
				border-radius: 4px;
				position: relative;
				background-color: #fff;
				width: 200px;
				margin: 0 0 40px;
				cursor: pointer;

				@media only screen and (min-width: 730px) {
					min-height: 180px;
					width: 190px;
					margin: 0 20px 30px 0;
				}

				&:last-of-type {
					@media only screen and (min-width: 730px) {
						margin-right: 0;
					}
				}

				.plan-icon-wrapper {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 2px solid white;
					position: absolute;
					top: -20px;
					left: 20px;
					color: white;

					.beyond-icon:before {
						content: "\f104";
					}

					.established-icon:before {
						content: "\f103";
					}

					.elevate-icon:before {
						content: "\f102";
					}

					.explore-icon:before {
						content: "\f101";
					}

					.entry-icon:before {
						content: "\f100";
					}
				}

				.plan-header {
					height: 100px;
					padding: 35px 20px 0;
					margin-bottom: 20px;

					@media only screen and (min-width: 730px) {
						padding: 25px 20px 0;
					}

					.plan-title {
						font-size: 24px;
						margin-bottom: 10px;

						@media only screen and (min-width: 730px) {
							font-size: 20px;
						}
					}

					.plan-subtitle {
						font-size: 18px;
						display: flex;
						flex-direction: column;
					}
				}

				.plan-action {
					position: relative;

					hr {
						top: 25%;
						border: 1px solid transparent;
						border-bottom: 1px solid rgba(0, 0, 0, 0.1);
						width: 100%;
						position: absolute;
						border-style: solid;
					}

					.btn-wrapper {
						display: flex;
						justify-content: center;
						z-index: 1;
						position: relative;
						margin-bottom: 10px;

						.select-btn {
							height: 36px;
							width: 70%;
							font-weight: 400;
							border: 1px solid rgba(0, 0, 0, 0.05);
							border-radius: 4px;
							box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
							background-color: #FFFFFF;
							outline: none;
							transition-duration: 0.2s;
							cursor: pointer;
						}
					}
				}

				&.entry {
					.plan-icon-wrapper {
						background-color: #9E74F8;
						color: #fff;
					}

					.plan-header {
						color: #9E74F8;
					}

					.plan-action > .btn-wrapper > .select-btn {
						color: #9E74F8;
					}

					&.active {
						background-color: #9E74F8;
					}

					&:hover:not(.active) {
						.plan-action > .btn-wrapper > .select-btn {
							background-color: #9E74F8;
							color: #fff;
						}
					}
				}

				&.explore {
					.plan-icon-wrapper {
						background-color: #18B8C2;
						color: #fff;
					}

					.plan-header {
						color: #18B8C2;
					}

					.plan-action > .btn-wrapper > .select-btn {
						color: #18B8C2;
					}

					&.active {
						background-color: #18B8C2;
					}

					&:hover:not(.active) {
						.plan-action > .btn-wrapper > .select-btn {
							background-color: #18B8C2;
							color: #fff;
						}
					}
				}

				&.established {
					.plan-icon-wrapper {
						background-color: #81C926;
						color: #fff;
					}

					.plan-header {
						color: #81C926;
					}

					.plan-action > .btn-wrapper > .select-btn {
						color: #81C926;
					}

					&.active {
						background-color: #81C926;
					}

					&:hover:not(.active) {
						.plan-action > .btn-wrapper > .select-btn {
							background-color: #81C926;
							color: #fff;
						}
					}
				}

				&.elevate {
					.plan-icon-wrapper {
						background-color: #FACA00;
						color: #fff;
					}

					.plan-header {
						color: #FACA00;
					}

					.plan-action > .btn-wrapper > .select-btn {
						color: #FACA00;
					}

					&.active {
						background-color: #FACA00;
					}

					&:hover:not(.active) {
						.plan-action > .btn-wrapper > .select-btn {
							background-color: #FACA00;
							color: #fff;
						}
					}
				}

				&.beyond {
					.plan-icon-wrapper {
						background-color: #FA5001;
						color: #fff;
					}

					.plan-header {
						color: #FA5001;
					}

					.plan-action > .btn-wrapper > .select-btn {
						color: #FA5001;
					}

					&.active {
						background-color: #FA5001;
					}

					&:hover:not(.active) {
						.plan-action > .btn-wrapper > .select-btn {
							background-color: #FA5001;
							color: #fff;
						}
					}
				}

				&.active {
					.plan-header {
						color: #fff;
					}

					.plan-action {
						hr,
						.btn-wrapper > .select-btn {
							border-color: #fff;
						}
					}

					.plan-features {
						color: #fff;

					}
				}
			}
		}
	}
}
