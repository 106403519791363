/** REQUEST PASSWORD SECTION **/
.request-password-section {
	.form-wrapper {
		padding-top: 50px;

		@media only screen and (min-width: 730px) {
			max-width: 400px;
			margin: 0 auto;
		}
	}
}