.password-container {
	.info-text {
		width: 80%;
		margin: 0 auto;
	}

	.password-form-container {
		max-width: 480px;
		width: 100%;
		padding: 15px;
		max-width: 480px;
		margin: 0 auto;
	}
}
